import React, { useEffect } from "react";
import styles from "./formBuilder.module.scss";
import Header from "./header/header";
import ChooseLogoAndName from "./formBuilderSteps/chooseLogoAndName/chooseLogoAndName";
import Footer from "./footer/footer";
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from "src/_elements/progressBar/progressBar";
import ChooseQuestions from "./formBuilderSteps/chooseQuestions/chooseQuestions";
import ChooseAnswerOptions from "./formBuilderSteps/chooseAnswerOptions/chooseAnswerOptions";
import EndingPage from "./formBuilderSteps/endingPage/endingPage";
import {
  setCurrentCreateFormPageAC,
  setCurrentFormPageAC,
  setQuestionsAC,
} from "src/store/actions";
import ChooseConditions from "./formBuilderSteps/chooseConditions/chooseConditions";
import { useNavigate } from "react-router-dom";
import ROUTES from "src/_utils/routes/routes";
import { Helmet } from "react-helmet";
import ChooseEndingPage from "./formBuilderSteps/chooseEndingPage/chooseEndingPage";
import ChooseHowShallWeContactYou from "./formBuilderSteps/chooseHowShallWeContactYou/chooseHowShallWeContactYou";

const FormBuilder = () => {
  const currentIndex = useSelector((state: any) => state.currentCreateFormPage);

  const token = localStorage.getItem("token");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const content = [
    { id: "tour-company", component: <ChooseLogoAndName /> },
    { id: "tour-questions", component: <ChooseQuestions /> },
    { id: "tour-answers", component: <ChooseAnswerOptions /> },
    { id: "tour-conditions", component: <ChooseConditions /> },
    { id: "tour-contact", component: <ChooseHowShallWeContactYou /> },
    { id: "tour-ending", component: <ChooseEndingPage /> },
    { id: "tour-ready", component: <EndingPage /> },
  ];

  useEffect(() => {
    if (!token) {
      navigate(ROUTES.HOME);
    }
  }, [token]);

  useEffect(() => {
    dispatch(setCurrentCreateFormPageAC(0));
    dispatch(setCurrentFormPageAC(0));
    dispatch(setQuestionsAC([{ question: "", answerOptions: null }]));
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>LeadQuiz | Form builder</title>
      </Helmet>
      <Header />
      <div className={styles.progressBarOuterWrapper}>
        <div className={styles.progressBarWrapper}>
          <ProgressBar
            currentStep={currentIndex}
            totalFormPagesCount={content.length - 1}
          />
        </div>
      </div>
      {content[currentIndex].component}
      <Footer content={content} />
    </div>
  );
};

export default FormBuilder;
