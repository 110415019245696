import React, { useEffect, useState } from "react";
import styles from "./chooseHowShallWeContactYou.module.scss";
import FormInput from "src/_elements/formInput/formInput";
import AddElementButton from "src/_elements/addElementButton/addElementButton";
import TextArea from "src/_elements/textArea/textArea";
import { useDispatch, useSelector } from "react-redux";
import { setHSWCYOtherInfoAC, setHSWCYOTitleAC } from "src/store/actions";
import crossIcon from "./../../../../assets/cross.svg";
import deleteIcon from "./../../../../assets/trash.svg";

const ChooseHowShallWeContactYou = () => {
  const dispatch = useDispatch();

  const HSWCYOTitle = useSelector((state: any) => state.HSWCYOTitle);

  const [elements, setElements] = useState<any>([]);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    dispatch(setHSWCYOtherInfoAC(elements));
  }, [elements]);

  const handleAddElement = () => {
    setShowPopup(true);
  };

  const handleElementSelect = (selectedElement: string) => {
    if (selectedElement) {
      let newElement;
      switch (selectedElement) {
        case "date":
          newElement = {
            type: "date",
            label: "Date",
            component: <FormInput value="01.01.2000" type="date" />,
          };
          break;
        case "time":
          newElement = {
            type: "time",
            label: "Time",
            component: <FormInput value="00:00" type="time" />,
          };
          break;
        case "text":
          newElement = {
            type: "text",
            label: "Input",
            component: <FormInput value="Text input" type="text" />,
          };
          break;
        case "textarea":
          newElement = {
            type: "textarea",
            label: "Text area",
            component: <TextArea value="Text area" />,
          };
          break;
        default:
          break;
      }
      if (newElement) {
        setElements([...elements, newElement]);
        setShowPopup(false);
      }
    }
  };

  const handleRemoveElement = (index: number) => {
    setElements(elements.filter((_: any, i: any) => i !== index));
  };

  const handleLabelChange = (index: number, newLabel: string) => {
    const updatedElements = [...elements];
    updatedElements[index].label = newLabel;
    setElements(updatedElements);
  };

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <h2>"How shall we contact you" step</h2>
        <div className={styles.editWrapper}>
          <div>
            <FormInput
              value={HSWCYOTitle}
              onChange={(e) => dispatch(setHSWCYOTitleAC(e.target.value))}
            />
          </div>
          <div className={styles.notEditable}>
            <div className={styles.nameSection}>
              <div>
                <p>*First name:</p>
                <FormInput value="First name" />
              </div>
              <div>
                <p>*Last name:</p>
                <FormInput value="Last name" />
              </div>
            </div>
            <div>
              <p>*Phone number:</p>
              <FormInput value="+1" />
            </div>
            <div>
              <p>*Email:</p>
              <FormInput value="Email" />
            </div>
          </div>

          <div className={styles.otherInfo}>
            {elements.map((element: any, index: number) => (
              <div key={index} className={styles.elementWrapper}>
                <input
                  type="text"
                  value={element.label}
                  onChange={(e) => handleLabelChange(index, e.target.value)}
                  className={styles.labelInput}
                />
                <div className={styles.elementAndDeleteBtn}>
                  {element.component}
                  <button
                    onClick={() => handleRemoveElement(index)}
                    className={styles.deleteButton}
                  >
                    <img src={deleteIcon} alt="delete" />
                  </button>
                </div>
              </div>
            ))}
            <AddElementButton onClick={handleAddElement}>Add</AddElementButton>
            {showPopup && (
              <div className={styles.popup}>
                <div className={styles.header}>
                  <h3>Select Element Type</h3>
                  <img
                    src={crossIcon}
                    alt="close"
                    onClick={() => setShowPopup(false)}
                  />
                </div>
                <ul>
                  <li onClick={(e) => handleElementSelect("text")}>Input</li>
                  <li onClick={(e) => handleElementSelect("textarea")}>
                    Text area
                  </li>
                  <li onClick={(e) => handleElementSelect("date")}>Date</li>
                  <li onClick={(e) => handleElementSelect("time")}>Time</li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseHowShallWeContactYou;
