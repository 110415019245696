const COUNTRY_CODES = [
  {
    code: "+247",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/AC.svg",
    country: "AC",
  },
  {
    code: "+376",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/AD.svg",
    country: "AD",
  },
  {
    code: "+971",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/AE.svg",
    country: "AE",
  },
  {
    code: "+93",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/AF.svg",
    country: "AF",
  },
  {
    code: "+1-268",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/AG.svg",
    country: "AG",
  },
  {
    code: "+1-264",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/AI.svg",
    country: "AI",
  },
  {
    code: "+355",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/AL.svg",
    country: "AL",
  },
  {
    code: "+374",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/AM.svg",
    country: "AM",
  },
  {
    code: "+244",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/AO.svg",
    country: "AO",
  },
  {
    code: "+672",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/AQ.svg",
    country: "AQ",
  },
  {
    code: "+54",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/AR.svg",
    country: "AR",
  },
  {
    code: "+1-684",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/AS.svg",
    country: "AS",
  },
  {
    code: "+43",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/AT.svg",
    country: "AT",
  },
  {
    code: "+61",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/AU.svg",
    country: "AU",
  },
  {
    code: "+297",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/AW.svg",
    country: "AW",
  },
  {
    code: "+358",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/AX.svg",
    country: "AX",
  },
  {
    code: "+994",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/AZ.svg",
    country: "AZ",
  },
  {
    code: "+387",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/BA.svg",
    country: "BA",
  },
  {
    code: "+1-246",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/BB.svg",
    country: "BB",
  },
  {
    code: "+880",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/BD.svg",
    country: "BD",
  },
  {
    code: "+590",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/BL.svg",
    country: "BL",
  },
  {
    code: "+1-441",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/BM.svg",
    country: "BM",
  },
  {
    code: "+673",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/BN.svg",
    country: "BN",
  },
  {
    code: "+591",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/BO.svg",
    country: "BO",
  },
  {
    code: "+599",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/BQ.svg",
    country: "BQ",
  },
  {
    code: "+55",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/BR.svg",
    country: "BR",
  },
  {
    code: "+1-242",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/BS.svg",
    country: "BS",
  },
  {
    code: "+975",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/BT.svg",
    country: "BT",
  },
  {
    code: "+47",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/BV.svg",
    country: "BV",
  },
  {
    code: "+267",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/BW.svg",
    country: "BW",
  },
  {
    code: "+375",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/BY.svg",
    country: "BY",
  },
  {
    code: "+501",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/BZ.svg",
    country: "BZ",
  },
  {
    code: "+1",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/CA.svg",
    country: "CA",
  },
  {
    code: "+61",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/CC.svg",
    country: "CC",
  },
  {
    code: "+243",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/CD.svg",
    country: "CD",
  },
  {
    code: "+236",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/CF.svg",
    country: "CF",
  },
  {
    code: "+242",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/CG.svg",
    country: "CG",
  },
  {
    code: "+41",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/CH.svg",
    country: "CH",
  },
  {
    code: "+225",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/CI.svg",
    country: "CI",
  },
  {
    code: "+682",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/CK.svg",
    country: "CK",
  },
  {
    code: "+56",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/CL.svg",
    country: "CL",
  },
  {
    code: "+237",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/CM.svg",
    country: "CM",
  },
  {
    code: "+86",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/CN.svg",
    country: "CN",
  },
  {
    code: "+57",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/CO.svg",
    country: "CO",
  },
  {
    code: "+506",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/CR.svg",
    country: "CR",
  },
  {
    code: "+53",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/CU.svg",
    country: "CU",
  },
  {
    code: "+238",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/CV.svg",
    country: "CV",
  },
  {
    code: "+599",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/CW.svg",
    country: "CW",
  },
  {
    code: "+61",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/CX.svg",
    country: "CX",
  },
  {
    code: "+357",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/CY.svg",
    country: "CY",
  },
  {
    code: "+420",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/CZ.svg",
    country: "CZ",
  },
  {
    code: "+49",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/DE.svg",
    country: "DE",
  },
  {
    code: "+253",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/DJ.svg",
    country: "DJ",
  },
  {
    code: "+45",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/DK.svg",
    country: "DK",
  },
  {
    code: "+1-767",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/DM.svg",
    country: "DM",
  },
  {
    code: "+1-809",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/DO.svg",
    country: "DO",
  },
  {
    code: "+213",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/DZ.svg",
    country: "DZ",
  },
  {
    code: "+593",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/EC.svg",
    country: "EC",
  },
  {
    code: "+372",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/EE.svg",
    country: "EE",
  },
  {
    code: "+20",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/EG.svg",
    country: "EG",
  },
  {
    code: "+212",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/EH.svg",
    country: "EH",
  },
  {
    code: "+291",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/ER.svg",
    country: "ER",
  },
  {
    code: "+34",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/ES.svg",
    country: "ES",
  },
  {
    code: "+251",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/ET.svg",
    country: "ET",
  },
  {
    code: "+32",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/EU.svg",
    country: "EU",
  },
  {
    code: "+358",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/FI.svg",
    country: "FI",
  },
  {
    code: "+679",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/FJ.svg",
    country: "FJ",
  },
  {
    code: "+500",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/FK.svg",
    country: "FK",
  },
  {
    code: "+691",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/FM.svg",
    country: "FM",
  },
  {
    code: "+298",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/FO.svg",
    country: "FO",
  },
  {
    code: "+33",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/FR.svg",
    country: "FR",
  },
  {
    code: "+241",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/GA.svg",
    country: "GA",
  },
  {
    code: "+44",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/GB.svg",
    country: "GB",
  },
  {
    code: "+1-473",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/GD.svg",
    country: "GD",
  },
  {
    code: "+995",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/GE.svg",
    country: "GE",
  },
  {
    code: "+594",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/GF.svg",
    country: "GF",
  },
  {
    code: "+44-1481",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/GG.svg",
    country: "GG",
  },
  {
    code: "+233",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/GH.svg",
    country: "GH",
  },
  {
    code: "+350",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/GI.svg",
    country: "GI",
  },
  {
    code: "+299",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/GL.svg",
    country: "GL",
  },
  {
    code: "+220",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/GM.svg",
    country: "GM",
  },
  {
    code: "+224",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/GN.svg",
    country: "GN",
  },
  {
    code: "+590",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/GP.svg",
    country: "GP",
  },
  {
    code: "+240",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/GQ.svg",
    country: "GQ",
  },
  {
    code: "+30",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/GR.svg",
    country: "GR",
  },
  {
    code: "+500",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/GS.svg",
    country: "GS",
  },
  {
    code: "+502",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/GT.svg",
    country: "GT",
  },
  {
    code: "+1-671",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/GU.svg",
    country: "GU",
  },
  {
    code: "+245",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/GW.svg",
    country: "GW",
  },
  {
    code: "+592",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/GY.svg",
    country: "GY",
  },
  {
    code: "+852",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/HK.svg",
    country: "HK",
  },
  {
    code: "+1-664",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/HM.svg",
    country: "HM",
  },
  {
    code: "+504",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/HN.svg",
    country: "HN",
  },
  {
    code: "+385",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/HR.svg",
    country: "HR",
  },
  {
    code: "+509",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/HT.svg",
    country: "HT",
  },
  {
    code: "+36",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/HU.svg",
    country: "HU",
  },
  {
    code: "+34",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/IC.svg",
    country: "IC",
  },
  {
    code: "+62",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/ID.svg",
    country: "ID",
  },
  {
    code: "+353",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/IE.svg",
    country: "IE",
  },
  {
    code: "+972",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/IL.svg",
    country: "IL",
  },
  {
    code: "+44-1624",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/IM.svg",
    country: "IM",
  },
  {
    code: "+91",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/IN.svg",
    country: "IN",
  },
  {
    code: "+246",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/IO.svg",
    country: "IO",
  },
  {
    code: "+964",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/IQ.svg",
    country: "IQ",
  },
  {
    code: "+98",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/IR.svg",
    country: "IR",
  },
  {
    code: "+354",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/IS.svg",
    country: "IS",
  },
  {
    code: "+39",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/IT.svg",
    country: "IT",
  },
  {
    code: "+44-1534",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/JE.svg",
    country: "JE",
  },
  {
    code: "+1-876",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/JM.svg",
    country: "JM",
  },
  {
    code: "+962",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/JO.svg",
    country: "JO",
  },
  {
    code: "+81",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/JP.svg",
    country: "JP",
  },
  {
    code: "+254",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/KE.svg",
    country: "KE",
  },
  {
    code: "+996",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/KG.svg",
    country: "KG",
  },
  {
    code: "+855",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/KH.svg",
    country: "KH",
  },
  {
    code: "+686",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/KI.svg",
    country: "KI",
  },
  {
    code: "+269",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/KM.svg",
    country: "KM",
  },
  {
    code: "+1-869",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/KN.svg",
    country: "KN",
  },
  {
    code: "+850",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/KP.svg",
    country: "KP",
  },
  {
    code: "+82",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/KR.svg",
    country: "KR",
  },
  {
    code: "+965",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/KW.svg",
    country: "KW",
  },
  {
    code: "+1-345",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/KY.svg",
    country: "KY",
  },
  {
    code: "+7",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/KZ.svg",
    country: "KZ",
  },
  {
    code: "+856",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/LA.svg",
    country: "LA",
  },
  {
    code: "+961",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/LB.svg",
    country: "LB",
  },
  {
    code: "+1-758",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/LC.svg",
    country: "LC",
  },
  {
    code: "+423",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/LI.svg",
    country: "LI",
  },
  {
    code: "+94",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/LK.svg",
    country: "LK",
  },
  {
    code: "+231",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/LR.svg",
    country: "LR",
  },
  {
    code: "+266",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/LS.svg",
    country: "LS",
  },
  {
    code: "+370",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/LT.svg",
    country: "LT",
  },
  {
    code: "+352",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/LU.svg",
    country: "LU",
  },
  {
    code: "+371",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/LV.svg",
    country: "LV",
  },
  {
    code: "+218",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/LY.svg",
    country: "LY",
  },
  {
    code: "+212",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/MA.svg",
    country: "MA",
  },
  {
    code: "+377",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/MC.svg",
    country: "MC",
  },
  {
    code: "+373",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/MD.svg",
    country: "MD",
  },
  {
    code: "+382",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/ME.svg",
    country: "ME",
  },
  {
    code: "+590",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/MF.svg",
    country: "MF",
  },
  {
    code: "+261",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/MG.svg",
    country: "MG",
  },
  {
    code: "+692",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/MH.svg",
    country: "MH",
  },
  {
    code: "+389",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/MK.svg",
    country: "MK",
  },
  {
    code: "+223",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/ML.svg",
    country: "ML",
  },
  {
    code: "+95",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/MM.svg",
    country: "MM",
  },
  {
    code: "+976",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/MN.svg",
    country: "MN",
  },
  {
    code: "+853",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/MO.svg",
    country: "MO",
  },
  {
    code: "+1-670",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/MP.svg",
    country: "MP",
  },
  {
    code: "+596",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/MQ.svg",
    country: "MQ",
  },
  {
    code: "+222",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/MR.svg",
    country: "MR",
  },
  {
    code: "+1-664",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/MS.svg",
    country: "MS",
  },
  {
    code: "+356",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/MT.svg",
    country: "MT",
  },
  {
    code: "+230",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/MU.svg",
    country: "MU",
  },
  {
    code: "+960",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/MV.svg",
    country: "MV",
  },
  {
    code: "+265",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/MW.svg",
    country: "MW",
  },
  {
    code: "+52",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/MX.svg",
    country: "MX",
  },
  {
    code: "+60",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/MY.svg",
    country: "MY",
  },
  {
    code: "+258",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/MZ.svg",
    country: "MZ",
  },
  {
    code: "+264",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/NA.svg",
    country: "NA",
  },
  {
    code: "+687",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/NC.svg",
    country: "NC",
  },
  {
    code: "+227",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/NE.svg",
    country: "NE",
  },
  {
    code: "+672",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/NF.svg",
    country: "NF",
  },
  {
    code: "+234",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/NG.svg",
    country: "NG",
  },
  {
    code: "+505",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/NI.svg",
    country: "NI",
  },
  {
    code: "+31",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/NL.svg",
    country: "NL",
  },
  {
    code: "+47",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/NO.svg",
    country: "NO",
  },
  {
    code: "+977",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/NP.svg",
    country: "NP",
  },
  {
    code: "+674",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/NR.svg",
    country: "NR",
  },
  {
    code: "+683",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/NU.svg",
    country: "NU",
  },
  {
    code: "+64",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/NZ.svg",
    country: "NZ",
  },
  {
    code: "+968",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/OM.svg",
    country: "OM",
  },
  {
    code: "+507",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/PA.svg",
    country: "PA",
  },
  {
    code: "+51",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/PE.svg",
    country: "PE",
  },
  {
    code: "+689",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/PF.svg",
    country: "PF",
  },
  {
    code: "+675",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/PG.svg",
    country: "PG",
  },
  {
    code: "+63",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/PH.svg",
    country: "PH",
  },
  {
    code: "+92",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/PK.svg",
    country: "PK",
  },
  {
    code: "+48",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/PL.svg",
    country: "PL",
  },
  {
    code: "+508",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/PM.svg",
    country: "PM",
  },
  {
    code: "+64",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/PN.svg",
    country: "PN",
  },
  {
    code: "+1",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/PR.svg",
    country: "PR",
  },
  {
    code: "+970",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/PS.svg",
    country: "PS",
  },
  {
    code: "+351",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/PT.svg",
    country: "PT",
  },
  {
    code: "+680",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/PW.svg",
    country: "PW",
  },
  {
    code: "+595",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/PY.svg",
    country: "PY",
  },
  {
    code: "+974",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/QA.svg",
    country: "QA",
  },
  {
    code: "+262",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/RE.svg",
    country: "RE",
  },
  {
    code: "+40",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/RO.svg",
    country: "RO",
  },
  {
    code: "+381",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/RS.svg",
    country: "RS",
  },
  {
    code: "+7",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/RU.svg",
    country: "RU",
  },
  {
    code: "+250",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/RW.svg",
    country: "RW",
  },
  {
    code: "+966",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/SA.svg",
    country: "SA",
  },
  {
    code: "+677",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/SB.svg",
    country: "SB",
  },
  {
    code: "+248",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/SC.svg",
    country: "SC",
  },
  {
    code: "+249",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/SD.svg",
    country: "SD",
  },
  {
    code: "+46",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/SE.svg",
    country: "SE",
  },
  {
    code: "+65",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/SG.svg",
    country: "SG",
  },
  {
    code: "+290",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/SH.svg",
    country: "SH",
  },
  {
    code: "+386",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/SI.svg",
    country: "SI",
  },
  {
    code: "+47",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/SJ.svg",
    country: "SJ",
  },
  {
    code: "+421",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/SK.svg",
    country: "SK",
  },
  {
    code: "+232",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/SL.svg",
    country: "SL",
  },
  {
    code: "+378",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/SM.svg",
    country: "SM",
  },
  {
    code: "+221",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/SN.svg",
    country: "SN",
  },
  {
    code: "+252",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/SO.svg",
    country: "SO",
  },
  {
    code: "+597",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/SR.svg",
    country: "SR",
  },
  {
    code: "+211",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/SS.svg",
    country: "SS",
  },
  {
    code: "+239",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/ST.svg",
    country: "ST",
  },
  {
    code: "+503",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/SV.svg",
    country: "SV",
  },
  {
    code: "+597",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/SX.svg",
    country: "SX",
  },
  {
    code: "+963",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/SY.svg",
    country: "SY",
  },
  {
    code: "+268",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/SZ.svg",
    country: "SZ",
  },
  {
    code: "+290",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/TA.svg",
    country: "TA",
  },
  {
    code: "+1-649",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/TC.svg",
    country: "TC",
  },
  {
    code: "+235",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/TD.svg",
    country: "TD",
  },
  {
    code: "+262",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/TF.svg",
    country: "TF",
  },
  {
    code: "+228",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/TG.svg",
    country: "TG",
  },
  {
    code: "+66",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/TH.svg",
    country: "TH",
  },
  {
    code: "+992",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/TJ.svg",
    country: "TJ",
  },
  {
    code: "+690",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/TK.svg",
    country: "TK",
  },
  {
    code: "+670",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/TL.svg",
    country: "TL",
  },
  {
    code: "+993",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/TM.svg",
    country: "TM",
  },
  {
    code: "+216",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/TN.svg",
    country: "TN",
  },
  {
    code: "+676",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/TO.svg",
    country: "TO",
  },
  {
    code: "+90",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/TR.svg",
    country: "TR",
  },
  {
    code: "+1-868",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/TT.svg",
    country: "TT",
  },
  {
    code: "+688",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/TV.svg",
    country: "TV",
  },
  {
    code: "+886",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/TW.svg",
    country: "TW",
  },
  {
    code: "+255",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/TZ.svg",
    country: "TZ",
  },
  {
    code: "+380",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/UA.svg",
    country: "UA",
  },
  {
    code: "+256",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/UG.svg",
    country: "UG",
  },
  {
    code: "+1-808",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/UM.svg",
    country: "UM",
  },
  {
    code: "+1",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/US.svg",
    country: "US",
  },
  {
    code: "+598",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/UY.svg",
    country: "UY",
  },
  {
    code: "+998",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/UZ.svg",
    country: "UZ",
  },
  {
    code: "+379",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/VA.svg",
    country: "VA",
  },
  {
    code: "+1-784",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/VC.svg",
    country: "VC",
  },
  {
    code: "+58",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/VE.svg",
    country: "VE",
  },
  {
    code: "+1-284",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/VG.svg",
    country: "VG",
  },
  {
    code: "+1-340",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/VI.svg",
    country: "VI",
  },
  {
    code: "+84",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/VN.svg",
    country: "VN",
  },
  {
    code: "+678",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/VU.svg",
    country: "VU",
  },
  {
    code: "+681",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/WF.svg",
    country: "WF",
  },
  {
    code: "+685",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/WS.svg",
    country: "WS",
  },
  {
    code: "+383",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/XK.svg",
    country: "XK",
  },
  {
    code: "+967",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/YE.svg",
    country: "YE",
  },
  {
    code: "+262",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/YT.svg",
    country: "YT",
  },
  {
    code: "+27",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/ZA.svg",
    country: "ZA",
  },
  {
    code: "+260",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/ZM.svg",
    country: "ZM",
  },
  {
    code: "+263",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/ZW.svg",
    country: "ZW",
  },
];

export default COUNTRY_CODES;
