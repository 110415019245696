import React, { useEffect, useState } from "react";
import styles from "./howShallWeContactYou.module.scss";
import FormInput from "src/_elements/formInput/formInput";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfoAC, setUserNameAC } from "src/store/actions";
import { IUserInfo } from "../interfaces/interfaces";
import COUNTRY_CODES from "src/_utils/countryCodes";
import dropdown from "src/assets/dropdown.svg";
import TextArea from "src/_elements/textArea/textArea";

const removeSpaces = (str: string) => str.replace(/\s+/g, "");

const HowShallWeContactYou = () => {
  const dispatch = useDispatch();

  const isGlobalError = useSelector(
    (state: any) => state.howShallWeContactYou.isGlobalError
  );
  const questionsList = useSelector((state: any) => state.questionsList);

  const [selectedCountry, setSelectedCountry] = useState(
    COUNTRY_CODES.find((obj) => obj.country === "US") ?? COUNTRY_CODES[0]
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(selectedCountry.code);
  const [email, setEmail] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dynamicAnswers, setDynamicAnswers] = useState<any>({});

  useEffect(() => {
    const userInfo = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      email: email,
      ...dynamicAnswers,
    };

    console.log(userInfo, "userInfo");

    dispatch(setUserNameAC(firstName + " " + lastName));
    dispatch(setUserInfoAC(userInfo));
  }, [firstName, lastName, phoneNumber, email, dynamicAnswers]);

  const handleCountryChange = (country: any) => {
    setIsDropdownOpen(false);
    setSelectedCountry(country);
    setPhoneNumber(country.code);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handlePhoneNumberChange = (e: any) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");

    if (numericValue.startsWith(selectedCountry.code.replace("+", ""))) {
      setPhoneNumber(`+${numericValue}`);
    } else {
      setPhoneNumber(
        `${selectedCountry.code}${numericValue.slice(
          selectedCountry.code.length
        )}`
      );
    }
  };

  const generateUniqueLabel = (
    label: string,
    index: number,
    labelsCount: any
  ) => {
    const cleanedLabel = removeSpaces(label);
    if (labelsCount[cleanedLabel] > 1) {
      return `${cleanedLabel}${index + 1}`;
    }
    return cleanedLabel;
  };

  const countLabels = (questions: any) => {
    return questions.reduce((acc: any, question: any) => {
      const cleanedLabel = removeSpaces(question.label);
      acc[cleanedLabel] = (acc[cleanedLabel] || 0) + 1;
      return acc;
    }, {});
  };

  const handleDynamicInputChange = (e: any, label: string) => {
    const { value } = e.target;
    const cleanedLabel = removeSpaces(label);
    setDynamicAnswers((prev: any) => ({
      ...prev,
      [cleanedLabel]: value,
    }));
  };

  const renderFormElement = (
    question: any,
    index: number,
    labelsCount: any
  ) => {
    const uniqueLabel = generateUniqueLabel(question.label, index, labelsCount);

    switch (question.type) {
      case "date":
        return (
          <div key={uniqueLabel}>
            <p className={styles.inputLabel}>{question.label}:</p>
            <FormInput
              type="date"
              value={dynamicAnswers[uniqueLabel] || ""}
              onChange={(e) => handleDynamicInputChange(e, uniqueLabel)}
            />
          </div>
        );
      case "time":
        return (
          <div key={uniqueLabel}>
            <p className={styles.inputLabel}>{question.label}:</p>
            <FormInput
              type="time"
              value={dynamicAnswers[uniqueLabel] || ""}
              onChange={(e) => handleDynamicInputChange(e, uniqueLabel)}
            />
          </div>
        );
      case "text":
        return (
          <div key={uniqueLabel}>
            <p className={styles.inputLabel}>{question.label}:</p>
            <FormInput
              type="text"
              value={dynamicAnswers[uniqueLabel] || ""}
              onChange={(e) => handleDynamicInputChange(e, uniqueLabel)}
            />
          </div>
        );
      case "textarea":
        return (
          <div key={uniqueLabel}>
            <p className={styles.inputLabel}>{question.label}:</p>
            <TextArea
              value={dynamicAnswers[uniqueLabel] || ""}
              onChange={(e) => handleDynamicInputChange(e, uniqueLabel)}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.questionTitle}>
        {questionsList.howShallWeContactYou.title}
      </p>
      {isGlobalError && (
        <p className={styles.errorMessage}>
          Please fill in all fields marked with *
        </p>
      )}
      <div className={styles.nameSection}>
        <div>
          <p className={styles.inputLabel}>*First name:</p>
          <FormInput
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="First name"
          />
        </div>
        <div>
          <p className={styles.inputLabel}>*Last name:</p>
          <FormInput
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Last name"
          />
        </div>
      </div>
      <p className={styles.inputLabel}>*Phone number:</p>
      <div className={styles.phoneNumberInputWrapper}>
        <div className={styles.customSelect} onClick={toggleDropdown}>
          <div className={styles.selectedOption}>
            <img src={selectedCountry.flag} alt={selectedCountry.code} />
            <img className={styles.dropdown} src={dropdown} alt="arrow" />
          </div>
          {isDropdownOpen && (
            <div className={styles.options}>
              {COUNTRY_CODES.map((country, index) => (
                <div
                  key={index}
                  className={styles.option}
                  onClick={() => handleCountryChange(country)}
                >
                  <p>{country.country}</p>
                  <img src={country.flag} alt={country.code} />
                </div>
              ))}
            </div>
          )}
        </div>
        <FormInput
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          type="tel"
          placeholder="Phone number"
        />
      </div>
      <p className={styles.inputLabel}>*Email:</p>
      <FormInput
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
      />
      <br />
      <div className={styles.otherInfo}>
        {questionsList.howShallWeContactYou.otherInfo.map(
          (question: any, index: number) => {
            const labelsCount = countLabels(
              questionsList.howShallWeContactYou.otherInfo
            );
            return renderFormElement(question, index, labelsCount);
          }
        )}
      </div>
    </div>
  );
};

export default HowShallWeContactYou;
