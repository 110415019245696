import { useEffect, useState } from "react";
import styles from "./formSubmissionEmailIntegration.module.scss";
import {
  getSubmissionEmailsListForFormApi,
  activateSubmissionEmailApi,
  addSubmissionEmailApi,
} from "src/_utils/api/api";
import crossIcon from "./../../../assets/cross.svg";
import { RotatingLines } from "react-loader-spinner";
import emailIcon from "./../../../assets/email.svg";
import Input from "src/_elements/input/input";
import ToggleSwitch from "src/_elements/toggleSwitch/toggleSwitch";

const FormSubmissionEmailIntegration: React.FC<any> = ({ formsArray }: any) => {
  const [selectedForm, setSelectedForm] = useState(formsArray[0]?.form.id);
  const [selectedFormName, setSelectedFormName] = useState(
    formsArray[0]?.form.name
  );
  const [submissionEmailsListForForm, setSubmissionEmailsListForForm] =
    useState<any>([]);
  const [newEmail, setNewEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [subject, setSubject] = useState("");
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    if (isSaved) {
      setTimeout(() => {
        setIsSaved(false);
      }, 2000);
    }
  }, [isSaved]);

  useEffect(() => {
    selectedForm &&
      getSubmissionEmailsListForFormApi(selectedForm).then((res) => {
        setSubmissionEmailsListForForm(res.data.data?.emails);
        setIsEnabled(res.data.data?.isEnabled);
        setSubject(res.data.data?.subject);
      });
  }, [selectedForm, isLoading]);

  const handleFormChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedForm(event.target.value);

    const selectedId = event.target.value;
    const selectedForm = formsArray.find(
      (form: any) => form.form.id === selectedId
    );

    if (selectedForm) {
      setSelectedFormName(selectedForm.form.name);
    }
  };
  const handleActivateEmail = () => {
    setIsLoading(true);

    const payload = {
      subject: `[New lead] - ${selectedFormName}`,
      formId: selectedForm,
    };

    activateSubmissionEmailApi(payload).then(() => setIsLoading(false));
  };

  const handleAddEmail = () => {
    if (newEmail && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail)) {
      setIsError(false);

      setSubmissionEmailsListForForm((prevItems: string) => [
        ...prevItems,
        newEmail,
      ]);

      setNewEmail("");
    } else {
      setIsError(true);
    }
  };

  const handleRemoveEmail = (index: number) => {
    const updatedEmails = submissionEmailsListForForm.filter(
      (_: any, i: number) => i !== index
    );
    setSubmissionEmailsListForForm(updatedEmails);
  };

  const handleSaveChanges = () => {
    setIsLoading(true);

    const payload = {
      subject: subject,
      emails: submissionEmailsListForForm,
      formId: selectedForm,
      isEnabled: isEnabled,
    };

    addSubmissionEmailApi(payload).then(() => {
      setIsLoading(false);
      setNewEmail("");
      setIsSaved(true);
    });
  };

  const submissionEmailsListForFormList =
    submissionEmailsListForForm &&
    submissionEmailsListForForm.map((el: any, index: number) => (
      <li key={index}>
        {el}
        <img
          src={crossIcon}
          alt="delete"
          onClick={() => handleRemoveEmail(index)}
          className={styles.crossIcon}
        />
      </li>
    ));

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <div className={styles.titleWrap}>
          <img height="25px" width="25px" src={emailIcon} alt="email" />
          <p className={styles.title}>EMAIL ALERT</p>
        </div>
        <div className={styles.content}>
          <div className={styles.subjectWrapper}>
            <p>Subject:</p>
            <Input
              value={subject}
              onChange={(e: any) => setSubject(e.target.value)}
              type="text"
            />
          </div>
          <div className={styles.emailsWrapper}>
            <div className={styles.leftContent}>
              <div className={styles.emailFrom}>
                <p>From email address:</p>
                <Input value="no-reply@leadquiz.com" type="text" />
              </div>
              <div>
                <p>Selected form:</p>
                <select
                  value={selectedForm}
                  onChange={handleFormChange}
                  className={styles.selectWrapper}
                >
                  {formsArray.map((option: any, index: number) => (
                    <option key={index} value={option.form.id}>
                      {option.form.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {!isLoading && (
              <div className={styles.emailsWrap}>
                <p>To email address:</p>
                <ul>
                  {submissionEmailsListForFormList}
                  {submissionEmailsListForForm ? (
                    <div className={styles.addEmailWrap}>
                      <p>Add email:</p>
                      {isError && <p className={styles.error}>Invalid email</p>}
                      <div className={styles.inputEmail}>
                        <input
                          value={newEmail}
                          onChange={(e) => setNewEmail(e.target.value)}
                          type="text"
                        />
                        <button className={styles.btn} onClick={handleAddEmail}>
                          add
                        </button>
                      </div>
                    </div>
                  ) : (
                    <button
                      className={styles.btn}
                      onClick={handleActivateEmail}
                    >
                      Activate
                    </button>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
        {submissionEmailsListForForm && (
          <div className={styles.checkboxWrapper}>
            <p>Active:</p>
            <ToggleSwitch value={isEnabled} toggleChange={setIsEnabled} />
          </div>
        )}
        <div className={styles.saveBtnWrapper}>
          {submissionEmailsListForForm && (
            <button onClick={handleSaveChanges}>
              {!isLoading ? (
                "Save"
              ) : (
                <RotatingLines
                  visible={true}
                  width="30"
                  strokeWidth="5"
                  animationDuration="0.75"
                  strokeColor="white"
                />
              )}
            </button>
          )}
          {isSaved && <p>Saved!</p>}
        </div>
      </div>
    </div>
  );
};

export default FormSubmissionEmailIntegration;
