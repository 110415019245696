export const validatePasswordSpecialSymbol = (password: string) => {
  const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;

  return specialCharacterRegex.test(password);
};

export const validatePasswordUpperCase = (password: string) => {
  const uppercaseRegex = /[A-Z]/;

  return uppercaseRegex.test(password);
};

export const validatePasswordDigit = (password: string) => {
  const digitRegex = /\d/;

  return digitRegex.test(password);
};

export const validatePasswordLength = (password: string) => {
  return password.length >= 5 && password.length <= 70;
};

export const validatePasswordLatinSymbols = (password: string) => {
  const latinRegex = /^[A-Za-z0-9!@#\$%\^\&*\)\(+=._-]+$/;

  return latinRegex.test(password);
};
