import React, { useEffect, useState } from "react";
import styles from "./changePasswordModal.module.scss";
import Modal from "src/_elements/modal/modal";
import Input from "src/_elements/input/input";
import {
  validatePasswordDigit,
  validatePasswordLatinSymbols,
  validatePasswordLength,
  validatePasswordSpecialSymbol,
  validatePasswordUpperCase,
} from "src/_utils/passwordValidator";
import { PASSWORD_ERROR_MESSAGES } from "src/_utils/passwordErrorMessages";
import { changePasswordApi } from "src/_utils/api/api";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { setIsUserPlanActiveAC } from "src/store/actions";
import ROUTES from "src/_utils/routes/routes";
import { useNavigate } from "react-router-dom";

const ChangePasswordModal: React.FC<any> = ({ isShow, setIsShow }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isNewOldError, setIsOldPasswordError] = useState(false);
  const [isNewPasswordError, setIsNewPasswordError] = useState(false);
  const [isRepeatNewPasswordError, setIsRepeatNewPasswordError] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordChangeSucces, setIsPasswordChangeSucces] = useState(false);

  useEffect(() => {
    if (isPasswordChangeSucces) {
      setTimeout(() => {
        dispatch(setIsUserPlanActiveAC(false));
        localStorage.removeItem("token");
        navigate(ROUTES.LOGIN);
      }, 1000);
    }
  }, [isPasswordChangeSucces]);

  const handleChangePassword = () => {
    if (oldPassword === "" || newPassword === "" || repeatPassword === "") {
      setErrorMessage("Please fill in all fields");
    } else if (
      !validatePasswordLatinSymbols(oldPassword) ||
      !validatePasswordSpecialSymbol(oldPassword) ||
      !validatePasswordUpperCase(oldPassword) ||
      !validatePasswordDigit(oldPassword) ||
      !validatePasswordLength(oldPassword)
    ) {
      setIsRepeatNewPasswordError(false);
      setIsNewPasswordError(false);
      setIsOldPasswordError(true);
      if (!validatePasswordLatinSymbols(oldPassword)) {
        setErrorMessage(PASSWORD_ERROR_MESSAGES.latinSymbols);
      } else if (!validatePasswordSpecialSymbol(oldPassword)) {
        setErrorMessage(PASSWORD_ERROR_MESSAGES.specialCharacter);
      } else if (!validatePasswordUpperCase(oldPassword)) {
        setErrorMessage(PASSWORD_ERROR_MESSAGES.uppercaseCharacter);
      } else if (!validatePasswordDigit(oldPassword)) {
        setErrorMessage(PASSWORD_ERROR_MESSAGES.numericCharacter);
      } else if (!validatePasswordLength(oldPassword)) {
        setErrorMessage(PASSWORD_ERROR_MESSAGES.charactersLength);
      }
    } else if (
      !validatePasswordLatinSymbols(newPassword) ||
      !validatePasswordSpecialSymbol(newPassword) ||
      !validatePasswordUpperCase(newPassword) ||
      !validatePasswordDigit(newPassword) ||
      !validatePasswordLength(newPassword)
    ) {
      setIsRepeatNewPasswordError(false);
      setIsOldPasswordError(false);
      setIsNewPasswordError(true);
      if (!validatePasswordLatinSymbols(newPassword)) {
        setErrorMessage(PASSWORD_ERROR_MESSAGES.latinSymbols);
      } else if (!validatePasswordSpecialSymbol(newPassword)) {
        setErrorMessage(PASSWORD_ERROR_MESSAGES.specialCharacter);
      } else if (!validatePasswordUpperCase(newPassword)) {
        setErrorMessage(PASSWORD_ERROR_MESSAGES.uppercaseCharacter);
      } else if (!validatePasswordDigit(newPassword)) {
        setErrorMessage(PASSWORD_ERROR_MESSAGES.numericCharacter);
      } else if (!validatePasswordLength(newPassword)) {
        setErrorMessage(PASSWORD_ERROR_MESSAGES.charactersLength);
      }
    } else if (
      !validatePasswordLatinSymbols(repeatPassword) ||
      !validatePasswordSpecialSymbol(repeatPassword) ||
      !validatePasswordUpperCase(repeatPassword) ||
      !validatePasswordDigit(repeatPassword) ||
      !validatePasswordLength(repeatPassword)
    ) {
      setIsOldPasswordError(false);
      setIsNewPasswordError(false);
      setIsRepeatNewPasswordError(true);
      if (!validatePasswordLatinSymbols(repeatPassword)) {
        setErrorMessage(PASSWORD_ERROR_MESSAGES.latinSymbols);
      } else if (!validatePasswordSpecialSymbol(repeatPassword)) {
        setErrorMessage(PASSWORD_ERROR_MESSAGES.specialCharacter);
      } else if (!validatePasswordUpperCase(repeatPassword)) {
        setErrorMessage(PASSWORD_ERROR_MESSAGES.uppercaseCharacter);
      } else if (!validatePasswordDigit(repeatPassword)) {
        setErrorMessage(PASSWORD_ERROR_MESSAGES.numericCharacter);
      } else if (!validatePasswordLength(repeatPassword)) {
        setErrorMessage(PASSWORD_ERROR_MESSAGES.charactersLength);
      }
    } else if (newPassword !== repeatPassword) {
      setIsNewPasswordError(true);
      setIsRepeatNewPasswordError(true);
      setErrorMessage("Passwords do not match");
    } else {
      setIsLoading(true);
      setIsOldPasswordError(false);
      setIsNewPasswordError(false);
      setIsRepeatNewPasswordError(false);
      setErrorMessage("");

      const payload = {
        oldPassword: oldPassword,
        newPassword: newPassword,
      };

      changePasswordApi(payload)
        .then(() => {
          setIsLoading(false);
          setIsPasswordChangeSucces(true);
        })
        .catch((err) => {
          console.log(err, "err");
          if (
            err.response.data.error.message === "ERROR_OLD_PASSWORD_NOT_MATCH"
          ) {
            setErrorMessage("Old password does not match");
            setIsLoading(false);
          }
        });
    }
  };

  const handleCloseModal = () => {
    setErrorMessage("");
    setIsOldPasswordError(false);
    setIsNewPasswordError(false);
    setIsRepeatNewPasswordError(false);
    setOldPassword("");
    setNewPassword("");
    setRepeatPassword("");
    setIsShow(false);
  };

  return (
    <Modal
      className={styles.wrapper}
      isShow={isShow}
      onClose={handleCloseModal}
    >
      <div className={styles.content}>
        <p className={styles.title}>Change password:</p>
        <div className={styles.inputs}>
          <div>
            {oldPassword !== "" && (
              <p className={styles.inputLabel}>Old password:</p>
            )}
            <Input
              className={isNewOldError ? styles.inputError : ""}
              placeholder="Old password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              type="password"
            />
          </div>
          <div>
            {newPassword !== "" && (
              <p className={styles.inputLabel}>New password:</p>
            )}
            <Input
              className={isNewPasswordError ? styles.inputError : ""}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="New password"
              type="password"
            />
          </div>
          <div>
            {repeatPassword !== "" && (
              <p className={styles.inputLabel}>Repeat new password:</p>
            )}
            <Input
              className={isRepeatNewPasswordError ? styles.inputError : ""}
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
              placeholder="Repeat new password"
              type="password"
            />
          </div>
        </div>
        <div className={styles.btnWrapper}>
          {errorMessage && (
            <p className={styles.errorMessage}>{errorMessage}</p>
          )}
          {isPasswordChangeSucces && (
            <p className={styles.successMessage}>
              Password was changed successfully.
            </p>
          )}
          <button onClick={handleChangePassword} className={styles.submitBtn}>
            {!isLoading ? (
              "Submit"
            ) : (
              <RotatingLines
                visible={true}
                width="30"
                strokeWidth="5"
                animationDuration="0.75"
                strokeColor="white"
              />
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
