import axios from "axios";
import {
  IActivateUserPayload,
  ILoginPayload,
  IPostFormPayload,
  IPostPlansListPayload,
  IRequestResetPasswordPayload,
  IResetPasswordPayload,
  ISignUpPayload,
} from "../interfaces/interfaces";
import { IGetIconsPayload } from "src/_elements/interfaces/interfaces";

const getToken = () => localStorage.getItem("token");

export const apiRoutes = {
  icons: "/icon-finder",
  iconsStyle: "/icon-finder/styles",
  plan: "/plan",
  availablePlans: "/plan/available",
  login: "/auth/login",
  signUp: "/auth/sign-up",
  activateUser: "/auth/activate-user",
  requestResetPasswordApi: "/auth/request-reset-password",
  resetPassword: "/auth/reset-password",
  requestChangeEmailApi: "/auth/request-change-email",
  changeEmail: "/auth/change-email",
  uploadFile: "/files/user/upload",
  forms: "/forms",
  stripeCreateSession: "/stripe/create-session",
  currentUserPlan: "/user-plan",
  cancelSubscriptionAtEnd: "/user-plan/cancel-at-end",
  subscriptionSchedule: "/stripe/subscription-schedule",
  changePlan: "/stripe/change-plan",
  reactivateCancelAtEnd: "/user-plan/reactivate-cancel-at-end",
  planAdditionalPurchase: "/plan-additional-purchase",
  allPlanAdditionalPurchase: "/plan-additional-purchase/plan",
  deleteAdditionalPurchase: "/plan-additional-purchase",
  planAdditionalPurchaseAvailable: "/plan-additional-purchase/available",
  planAdditionalPurchaseCurrent:
    "/plan-additional-purchase/current-period-purchases",
  stripeCreatePurchaseAdditionalSession:
    "/stripe/create-purchase-additional-session",
  cname: "/cname",
  publishForm: "/forms/publish-form",
  publishedForm: "/forms/published",
  currentUser: "/user",
  findByEmail: "/user/find-by-email",
  formSubmission: "/form-submission",
  submissionEmailForm: "/submission-email/form",
  submissionEmail: "/submission-email",
  createPersonalPlan: "/plan/personal-plan",
  unpublishForm: "/forms/unpublish-form",
  changePassword: "/auth/change-password",
  getUserDataById: "/user/user",
};

export const getPlansListApi = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}${apiRoutes.plan}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

export const getAvailablePlansListApi = () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}${apiRoutes.availablePlans}`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const postPlansListApi = (payload: IPostPlansListPayload) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${apiRoutes.plan}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const patchPlansListApi = (
  payload: IPostPlansListPayload,
  id: string
) => {
  return axios.patch(
    `${process.env.REACT_APP_API_URL}${apiRoutes.plan}/${id}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const getIconsApi = (payload: IGetIconsPayload) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${apiRoutes.icons}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const getIconsStyleApi = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}${apiRoutes.iconsStyle}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

export const loginApi = (payload: ILoginPayload) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${apiRoutes.login}`,
    payload
  );
};

export const signUpApi = (payload: ISignUpPayload) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${apiRoutes.signUp}`,
    payload
  );
};

export const activateUserApi = (payload: IActivateUserPayload) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${apiRoutes.activateUser}`,
    payload
  );
};

export const requestResetPasswordApi = (
  payload: IRequestResetPasswordPayload
) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${apiRoutes.requestResetPasswordApi}`,
    payload
  );
};

export const resetPasswordApi = (payload: IResetPasswordPayload) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${apiRoutes.resetPassword}`,
    payload
  );
};

export const requestChangeEmailApi = (payload: any) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${apiRoutes.requestChangeEmailApi}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const changeEmailApi = (payload: any) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${apiRoutes.changeEmail}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const uploadFileApi = (formData: FormData) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${apiRoutes.uploadFile}`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const postFormApi = (payload: IPostFormPayload) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${apiRoutes.forms}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const getFormsApi = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}${apiRoutes.forms}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

export const deleteFormApi = (id: string) => {
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${apiRoutes.forms}/${id}`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const unpublishFormApi = (payload: any) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${apiRoutes.unpublishForm}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const getFormApi = (id: string | undefined) => {
  return axios.get(`${process.env.REACT_APP_API_URL}${apiRoutes.forms}/${id}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

export const getPublishedFormApi = (cname: string) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}${apiRoutes.publishedForm}/${cname}`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const getCurrentUserPlanApi = () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}${apiRoutes.currentUserPlan}`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const cancelSubscriptionAtEndApi = () => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${apiRoutes.cancelSubscriptionAtEnd}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const getSubscriptionScheduleApi = () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}${apiRoutes.subscriptionSchedule}`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const changePlanApi = (priceId: string) => {
  return axios.patch(
    `${process.env.REACT_APP_API_URL}${apiRoutes.changePlan}`,
    { newPriceId: priceId },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const reactivatePlatApi = () => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${apiRoutes.reactivateCancelAtEnd}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const createPlanAdditionalPurchaseApi = (value: any) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${apiRoutes.planAdditionalPurchase}`,
    value,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const getAllPlanAdditionalPurchaseApi = (planId: string) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}${apiRoutes.allPlanAdditionalPurchase}/${planId}`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const deleteAdditionalPurchase = (purchaseItemId: string) => {
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${apiRoutes.deleteAdditionalPurchase}/${purchaseItemId}`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const getPlanAdditionalPurchaseAvailableApi = () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}${apiRoutes.planAdditionalPurchaseAvailable}`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const getPlanAdditionalPurchaseCurrentApi = () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}${apiRoutes.planAdditionalPurchaseCurrent}`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const publishFormApi = (payload: any) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${apiRoutes.publishForm}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const getCustomSubdomainsListApi = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}${apiRoutes.cname}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

export const getCurrentUserInfoApi = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}${apiRoutes.currentUser}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

export const findUserInfoByEmailApi = (email: string) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}${apiRoutes.findByEmail}/${email}`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const sendUserAnswersApi = (payload: any) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${apiRoutes.formSubmission}`,
    payload
  );
};

export const getUserAnswersForFormApi = (payload: any) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}${apiRoutes.formSubmission}/${payload.formId}`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        pageSize: payload.pageSize,
        page: payload.page,
        searchUsernameText: payload.searchUsernameText,
        startDate: payload.startDate,
        endDate: payload.endDate,
      },
    }
  );
};

export const getSubmissionEmailsListForFormApi = (formId: string) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}${apiRoutes.submissionEmailForm}/${formId}`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const activateSubmissionEmailApi = (payload: any) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${apiRoutes.submissionEmail}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const addSubmissionEmailApi = (payload: any) => {
  return axios.patch(
    `${process.env.REACT_APP_API_URL}${apiRoutes.submissionEmail}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const createPeronalPlansApi = (
  payload: IPostPlansListPayload,
  userId: string
) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${apiRoutes.createPersonalPlan}/${userId}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const changePasswordApi = (payload: any) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${apiRoutes.changePassword}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

export const getUserDataByIdApi = (userId: any) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}${apiRoutes.getUserDataById}/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};
